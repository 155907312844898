import React from 'react';
import SEO from '../layout/seo';
import { Page, Section } from '../layout/page';

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <Section color="black">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Page>
);

export default NotFoundPage;
